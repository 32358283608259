import { ReactNode } from 'react';
import {
  CountryDto,
  countryData,
  countriesAndPhNoLength,
} from '@leapfinance/auth-kit';
import { MIDDLE_EAST_COUNTRIES_IN_FORM } from 'utils/countryUtils';

type Option = {
  label: string | ReactNode;
  value: string;
};

export const countryOptions: Option[] = Object.keys(countryData)
  .filter((item1) =>
    MIDDLE_EAST_COUNTRIES_IN_FORM.find((item2) => item2 === item1),
  )
  .map((item) => ({
    label: (
      <p className="flex items-center gap-x-2" key={item}>
        <img
          src={countryData[item].flag}
          alt={`${item} flag`}
          width={28}
          height={20}
        />
        <span className="inline-block font-bold">
          +{countryData[item].code}
        </span>
      </p>
    ),
    value: countryData[item].code,
  }));

export const validatePhoneNumberOnCountry = (
  number: string | undefined,
  countryCode: string,
): boolean => {
  const country = Object.keys(countryData).find(
    (item) => countryData[item].code === countryCode,
  ) as CountryDto;
  if (number?.startsWith('0')) {
    if (number?.length === (countriesAndPhNoLength[country] as number) + 1)
      return true;
    return false;
  }
  if (number?.length === countriesAndPhNoLength[country]) return true;
  return false;
};
