import * as React from 'react';
import { debounce } from 'utils/utils';

export interface ButtonProps {
  /**
   * If `true`, the button will show a spinner.
   */
  isLoading?: boolean;
  /**
   * If `true`, the button will be disabled.
   */
  isDisable?: boolean;
  /**
   * If `true`, the button will take up the full width of its container.
   */
  isFullWidth?: boolean;
  /**
   * The html button type to use.
   */
  type?: 'button' | 'reset' | 'submit';
  /**
   * Is this the principal call to action on the page?
   */
  variant?: ButtonVariantInterface;
  /**
   * How large should the button be?
   */
  size?: ButtonSizeInterface;
  /**
   * Button contents
   */
  children: React.ReactNode;
  /**
   * Optional click handler
   */
  onClick?: () => void;
  /**
   * add custom css
   */
  customCss?: string;
  /**
   * adds debouncing delay for button click calls
   */
  delay?: number;

  disableCss?: string;
  /**
   * will use this css when button is disabled if not passed will use the default disable styling
   *  */
  responsiveButton?: ResponsiveButtonProps;
  /*
  making button responsive just pass mobilevariantSize and desktopVariantSize and it will show the mobilevariantSize in mobile and desktopvariantSize in desktop
  */
}

type ResponsiveButtonProps = {
  mobileVariant: ButtonSizeInterface;
  desktopVariant: ButtonSizeInterface;
};

type ButtonSizeInterface = 'small' | 'medium' | 'large';
export type ButtonVariantInterface =
  | 'primary'
  | 'secondary'
  | 'success'
  | 'danger'
  | 'white'
  | 'darkBlue'
  | 'warning'
  | 'gradient_orange'
  | 'blue-text'
  | 'white_bg_primary_text'
  | 'bgNone'
  | 'gradient_blue';

/**
 * Primary UI component for user interaction
 */
export const Button: React.FC<ButtonProps> = ({
  variant = 'primary',
  size = 'medium',
  children,
  onClick,
  isDisable,
  isFullWidth,
  type = 'button',
  customCss,
  delay = 30,
  disableCss,
  responsiveButton,
  ...props
}) => {
  const buttonTypeCss = (buttonType: ButtonVariantInterface) => {
    if (isDisable) {
      return disableCss
        ? disableCss
        : 'text-gray-400 bg-lightgray-100 border-lightgray-100';
    }
    switch (buttonType) {
      case 'primary':
        return 'text-white bg-primary';
      case 'secondary':
        return 'text-primary bg-white border-primary';
      case 'success':
        return 'text-white bg-green-400 border-green-400';
      case 'danger':
        return 'bg-red-400 text-white border-red-400 border';
      case 'white':
        return 'text-blue-100 bg-white border-white';
      case 'darkBlue':
        return 'text-white bg-blue-100 border-blue-100';
      case 'warning':
        return 'bg-yellow-850 text-blue-100 border-yellow-850';
      case 'gradient_orange':
        return 'bg-yellow-to-orange cursor-pointer text-white border-white';
      case 'blue-text':
        return 'text-primary bg-lightgray-175 border-white';
      case 'white_bg_primary_text':
        return 'text-primary bg-white border-lightgray-175';
      case 'bgNone':
        return 'bg-none border-lightgray-175';
      case 'gradient_blue':
        return 'bg-[linear-gradient(97.59deg,#635EFF_26.66%,#5200FF_78.98%)] shadow-[0px_4px_8px_rgba(67,54,149,0.08)] text-white';
      default:
        return '';
    }
  };

  const getResponsiveStyles = (variants: ResponsiveButtonProps): string => {
    const stylesForSmallMediumLarge = {
      small: 'text-sm px-2 py-1',
      medium: 'text-base px-4 py-2',
      large: 'text-xl px-6 py-2',
    };

    let cssStyles = stylesForSmallMediumLarge[variants.desktopVariant]
      .split(' ')
      .map((item) => `md:${item}`)
      .join(' ');

    cssStyles = `${cssStyles} ${
      stylesForSmallMediumLarge[variants.mobileVariant]
    }`;

    return cssStyles;
  };

  const buttonSizeCss = (buttonSize: ButtonSizeInterface): string => {
    if (responsiveButton) {
      return getResponsiveStyles(responsiveButton);
    }

    switch (buttonSize) {
      case 'small':
        return 'text-sm px-2 py-1';
      case 'medium':
        return 'text-base md:px-4 py-2';
      case 'large':
        return 'text-xl px-6 py-2';
      default:
        return '';
    }
  };

  // added debouncing to avoid button thrashing
  const onClickHandler = debounce(() => {
    if (!isDisable && onClick) {
      onClick();
    }
  }, delay);

  return (
    <button
      type="button"
      className={[
        'font-bold rounded border',
        buttonTypeCss(variant),
        buttonSizeCss(size),
        isDisable ? 'cursor-not-allowed' : 'cursor-pointer',
        isFullWidth && 'w-full',
        customCss ? customCss : '',
      ].join(' ')}
      onClick={onClickHandler}
      {...props}
    >
      {children}
    </button>
  );
};
