import { countryOptions } from 'components/DemoForm/countriesDetails';
import {
  emailRegex,
  indianMobileRegex,
  nameRegex,
  tenDigitMobileRegex,
} from 'DataMapper/RegexConstants';

export enum IeltsEliteFormFields {
  ieltsExamType = 'ieltsExamType',
  whatCareerStage = 'whatCareerStage',
  whenIelts = 'whenIelts',
  signinPurpose = 'signinPurpose',
  name = 'name',
  emailId = 'emailId',
  phone = 'phone',
  countryCode = 'countryCode',
}
export const IeltsEliteFormFieldsIds = {
  [IeltsEliteFormFields.ieltsExamType]: 1,
  [IeltsEliteFormFields.whatCareerStage]: 2,
  [IeltsEliteFormFields.whenIelts]: 3,
  [IeltsEliteFormFields.signinPurpose]: 8,
  [IeltsEliteFormFields.name]: 4,
  [IeltsEliteFormFields.emailId]: 5,
  [IeltsEliteFormFields.phone]: 6,
  [IeltsEliteFormFields.countryCode]: 7,
};
export const ieltsEliteFormRequiredFields = [
  IeltsEliteFormFields.ieltsExamType,
  IeltsEliteFormFields.whatCareerStage,
  IeltsEliteFormFields.whenIelts,
  IeltsEliteFormFields.signinPurpose,
  IeltsEliteFormFields.name,
  IeltsEliteFormFields.emailId,
  IeltsEliteFormFields.phone,
  IeltsEliteFormFields.countryCode,
];
export enum ieltsExamType {
  ACADEMIC = 'ACADEMIC',
  GENERAL = 'GENERAL',
  OTHER = 'OTHER',
}
export enum ieltsExamTime {
  WITHIN_3_MONTHS = 'WITHIN_3_MONTHS',
  WITHIN_3_6_MONTHS = 'WITHIN_3_6_MONTHS',
  BOOKED_EXAM = 'BOOKED_EXAM',
}
export enum ieltsCareerStage {
  STUDENT = 'STUDENT',
  WORKING = 'WORKING',
  RECENTLY_GRADUATED = 'RECENTLY_GRADUATED',
}
export enum SIGN_IN_PURPOSE {
  LIVE_CLASSES = 'LIVE_CLASSES',
  RECORDED_CLASSES = 'RECORDED_CLASSES',
  NOT_SURE = 'NOT_SURE',
}
export enum WHEN_IELTS {
  WITHIN_3_MONTHS = 'WITHIN_3_MONTHS',
  WITHIN_2_MONTHS = 'WITHIN_2_MONTHS',
  WITHIN_2_12_MONTHS = 'WITHIN_2_12_MONTHS',
  BOOKED_EXAM = 'BOOKED_EXAM',
  WITHIN_1_YEAR = 'WITHIN_1_YEAR',
  NOT_DECIDED = 'NOT_DECIDED',
  WITHIN_3_6_MONTHS = 'WITHIN_3_6_MONTHS',
  AFTER_6_MONTHS = 'AFTER_6_MONTHS',
}

export const indianPhone = {
  label: 'Phone Number*',
  type: 'textInput',
  fieldValue: IeltsEliteFormFields.phone,
  options: [],
  default_visibility: true,
  visibility_dependency: {
    dependencies: [
      {
        question_id: 0,
        value: [],
      },
    ],
  },
  validation: {
    pattern: indianMobileRegex,
    errorMessage: ' Please enter correct phone number',
  },
};
export const formDetails = {
  name: {
    label: 'Name*',
    type: 'textInput',
    fieldValue: IeltsEliteFormFields.name,
    question_text: 'Enter your details to book IELTS Demo',
    options: [],
    default_visibility: true,
    visibility_dependency: {
      dependencies: [
        {
          question_id: 0,
          value: [],
        },
      ],
    },
    validation: {
      pattern: nameRegex,
      errorMessage: 'Please enter correct name',
    },
  },
  email: {
    label: 'Email Address*',
    type: 'textInput',
    fieldValue: IeltsEliteFormFields.emailId,
    options: [],
    default_visibility: true,
    visibility_dependency: {
      dependencies: [
        {
          question_id: 0,
          value: [],
        },
      ],
    },
    validation: {
      pattern: emailRegex,
      errorMessage: 'Please enter correct E-mail',
    },
  },
  phone: {
    label: 'Phone Number*',
    type: 'textInput',
    fieldValue: IeltsEliteFormFields.phone,
    options: [],
    default_visibility: true,
    visibility_dependency: {
      dependencies: [
        {
          question_id: 0,
          value: [],
        },
      ],
    },
    validation: {
      pattern: tenDigitMobileRegex,
      errorMessage: ' Please enter correct phone number',
    },
  },
  countryCode: {
    label: 'Phone Number*',
    type: 'phoneWithCountry',
    fieldValue: IeltsEliteFormFields.countryCode,
    question_text: 'When are you giving IELTS?',
    options: countryOptions,
    default_visibility: true,
    validation: { pattern: '', errorMessage: '' },
  },
};

export const ieltsEliteJsonV3: any = {
  form_id: 'Ielts Demo Form',
  form_meta: {
    title: '',
    image: '',
  },
  no_of_steps: 6,
  page_distribution: [3, 3],
  is_auto_progressive: true,
  required: ieltsEliteFormRequiredFields,
  fields: [
    {
      question_id: 1,
      label: 'Ielts Type',
      type: 'single_select_tile',
      fieldValue: IeltsEliteFormFields.ieltsExamType,
      question_text: 'What is your IELTS exam type?',
      options: [
        {
          label: (
            <span className="!font-[Inter]">
              IELTS <br className="md:hidden" /> General
            </span>
          ),
          value: ieltsExamType.GENERAL,
        },
        {
          label: (
            <span className="!font-[Inter]">
              IELTS <br className="md:hidden" /> Academic
            </span>
          ),
          value: ieltsExamType.ACADEMIC,
        },
        {
          label: 'Not Sure',
          value: ieltsExamType.OTHER,
        },
      ],
      default_visibility: true,
      validation: { pattern: '', errorMessage: '' },
    },
    {
      question_id: 2,
      label: 'Ielts Type',
      type: 'single_select_tile',
      fieldValue: IeltsEliteFormFields.whatCareerStage,
      question_text: 'What are you currently doing?',
      options: [
        {
          label: 'Student',
          value: ieltsCareerStage.STUDENT,
        },
        {
          label: 'Working',
          value: ieltsCareerStage.WORKING,
        },
        {
          label: 'Recently Graduated',
          value: ieltsCareerStage.RECENTLY_GRADUATED,
        },
      ],
      default_visibility: true,
      validation: { pattern: '', errorMessage: '' },
    },
    {
      question_id: 3,
      label: 'Ielts Type',
      type: 'single_select_tile',
      fieldValue: IeltsEliteFormFields.whenIelts,
      question_text: 'When are you giving IELTS?',
      options: [
        {
          label: 'Within 3 months',
          value: ieltsExamTime.WITHIN_3_MONTHS,
        },
        {
          label: 'Within 3-6 months',
          value: ieltsExamTime.WITHIN_3_6_MONTHS,
        },
        {
          label: 'Already Booked',
          value: ieltsExamTime.BOOKED_EXAM,
        },
      ],
      default_visibility: true,
      validation: { pattern: '', errorMessage: '' },
    },
    {
      question_id: 4,
      ...formDetails.name,
    },
    {
      question_id: 5,
      ...formDetails.email,
    },
    {
      question_id: 6,
      ...formDetails.phone,
    },
  ],
};

export const ieltsEliteJsonV6: any = {
  form_id: 'Ielts Demo Form',
  form_meta: {
    title: '',
    image: '',
  },
  no_of_steps: 6,
  page_distribution: [3, 3],
  is_auto_progressive: true,
  required: ieltsEliteFormRequiredFields,
  fields: [
    {
      question_id: 1,
      label: 'Ielts Type',
      type: 'single_select_tile',
      fieldValue: IeltsEliteFormFields.ieltsExamType,
      question_text: 'What is your IELTS exam type?',
      options: [
        {
          label: (
            <span className="!font-[Inter]">
              IELTS <br className="md:hidden" /> General
            </span>
          ),
          value: ieltsExamType.GENERAL,
        },
        {
          label: (
            <span className="!font-[Inter]">
              IELTS <br className="md:hidden" /> Academic
            </span>
          ),
          value: ieltsExamType.ACADEMIC,
        },
        {
          label: 'Not Sure',
          value: ieltsExamType.OTHER,
        },
      ],
      default_visibility: true,
      validation: { pattern: '', errorMessage: '' },
    },
    {
      question_id: 2,
      label: 'Ielts Type',
      type: 'single_select_tile',
      fieldValue: IeltsEliteFormFields.whatCareerStage,
      question_text: 'What are you currently doing?',
      options: [
        {
          label: 'Student',
          value: ieltsCareerStage.STUDENT,
        },
        {
          label: 'Working',
          value: ieltsCareerStage.WORKING,
        },
        {
          label: 'Recently Graduated',
          value: ieltsCareerStage.RECENTLY_GRADUATED,
        },
      ],
      default_visibility: true,
      validation: { pattern: '', errorMessage: '' },
    },
    {
      question_id: 3,
      label: 'Ielts Type',
      type: 'single_select_tile',
      fieldValue: IeltsEliteFormFields.whenIelts,
      question_text: 'When are you giving IELTS?',
      options: [
        {
          label: 'Within 3 months',
          value: ieltsExamTime.WITHIN_3_MONTHS,
        },
        {
          label: 'Within 3-6 months',
          value: ieltsExamTime.WITHIN_3_6_MONTHS,
        },
        {
          label: 'Already Booked',
          value: ieltsExamTime.BOOKED_EXAM,
        },
      ],
      default_visibility: true,
      validation: { pattern: '', errorMessage: '' },
    },
    {
      question_id: 4,
      ...formDetails.name,
      question_text: 'Enter your details to get access',
    },
    {
      question_id: 5,
      ...formDetails.email,
    },
    {
      question_id: 6,
      ...indianPhone,
    },
  ],
};

export const ieltsEliteJsonV8: any = {
  form_id: 'Ielts Demo Form',
  form_meta: {
    title: '',
    image: '',
  },
  no_of_steps: 3,
  page_distribution: [3],
  is_auto_progressive: true,
  required: ieltsEliteFormRequiredFields,
  fields: [
    {
      question_id: 1,
      ...formDetails.name,
      question_text: 'Enter your details to get access',
    },
    {
      question_id: 2,
      ...formDetails.email,
    },
    {
      question_id: IeltsEliteFormFieldsIds.countryCode,
      ...formDetails.countryCode,
    },
    {
      question_id: IeltsEliteFormFieldsIds.phone,
      ...formDetails.phone,
    },
  ],
};
export const ieltsEliteJsonV7: any = {
  form_id: 'Ielts Demo Form',
  form_meta: {
    title: '',
    image: '',
  },
  no_of_steps: 3,
  page_distribution: [3],
  is_auto_progressive: true,
  required: ieltsEliteFormRequiredFields,
  fields: [
    {
      question_id: 1,
      ...formDetails.name,
      question_text: 'Enter your details to get access',
    },
    {
      question_id: 2,
      ...formDetails.email,
    },
    {
      question_id: 4,
      ...indianPhone,
    },
  ],
};

export const ieltsEliteJsonV5: any = {
  form_id: 'Ielts Demo Form V3',
  form_meta: {
    title: '',
    image: '',
  },
  no_of_steps: 7,
  page_distribution: [3, 1, 3],
  is_auto_progressive: true,
  required: ieltsEliteFormRequiredFields,
  fields: [
    {
      question_id: IeltsEliteFormFieldsIds.ieltsExamType,
      label: 'Ielts Type',
      type: 'single_select_tile',
      fieldValue: IeltsEliteFormFields.ieltsExamType,
      question_text: (
        <span className="text-[#0A0926]">What is your IELTS exam type?</span>
      ),
      options: [
        {
          label: (
            <span className="!font-[Inter]">
              IELTS <br className="md:hidden" /> General
            </span>
          ),
          value: ieltsExamType.GENERAL,
        },
        {
          label: (
            <span className="!font-[Inter]">
              IELTS <br className="md:hidden" /> Academic
            </span>
          ),
          value: ieltsExamType.ACADEMIC,
        },
        {
          label: 'Not Sure',
          value: ieltsExamType.OTHER,
        },
      ],
      default_visibility: true,
      validation: { pattern: '', errorMessage: '' },
    },
    {
      question_id: IeltsEliteFormFieldsIds.whatCareerStage,
      label: 'Ielts Type',
      type: 'single_select_tile',
      fieldValue: IeltsEliteFormFields.whatCareerStage,
      question_text: (
        <span className="text-[#0A0926]">What are you currently doing?</span>
      ),
      options: [
        {
          label: 'Student',
          value: ieltsCareerStage.STUDENT,
        },
        {
          label: 'Working',
          value: ieltsCareerStage.WORKING,
        },
        {
          label: 'Recently Graduated',
          value: ieltsCareerStage.RECENTLY_GRADUATED,
        },
      ],
      default_visibility: true,
      validation: { pattern: '', errorMessage: '' },
    },
    {
      question_id: IeltsEliteFormFieldsIds.whenIelts,
      label: 'Ielts Type',
      type: 'single_select_tile',
      fieldValue: IeltsEliteFormFields.whenIelts,
      question_text: (
        <span className="text-[#0A0926]">When are you giving IELTS?</span>
      ),
      options: [
        {
          label: 'Within 3 months',
          value: ieltsExamTime.WITHIN_3_MONTHS,
        },
        {
          label: 'Already Booked',
          value: ieltsExamTime.BOOKED_EXAM,
        },
        {
          label: 'Not sure yet',
          value: ieltsExamTime.WITHIN_3_6_MONTHS,
        },
      ],
      default_visibility: true,
      validation: { pattern: '', errorMessage: '' },
    },
    {
      question_id: IeltsEliteFormFieldsIds.signinPurpose,
      label: 'Onboarding purpose',
      type: 'single_select_tile_v2',
      fieldValue: IeltsEliteFormFields.signinPurpose,
      question_text: (
        <span className="text-[#0A0926]">
          How would you like to prepare for IELTS?
        </span>
      ),
      options: [
        {
          label: (
            <p>
              <span className="font-bold">Live classes</span> (teaching) with
              personalised attention
            </p>
          ),
          value: SIGN_IN_PURPOSE.LIVE_CLASSES,
        },
        {
          label: (
            <p>
              <span className="font-bold">Recorded classes </span> and{' '}
              <span className="font-bold">mock test</span> evaluation by top
              trainers
            </p>
          ),
          value: SIGN_IN_PURPOSE.RECORDED_CLASSES,
        },
        {
          label: 'I am not sure right now',
          value: SIGN_IN_PURPOSE.NOT_SURE,
        },
      ],
      default_visibility: true,
      validation: { pattern: '', errorMessage: '' },
    },
    {
      question_id: IeltsEliteFormFieldsIds.name,
      //@ts-ignore
      question_text: (
        <span className="text-[#0A0926]">
          Enter your details to book IELTS Masterclass
        </span>
      ),
      ...formDetails.name,
    },
    {
      question_id: IeltsEliteFormFieldsIds.emailId,
      ...formDetails.email,
    },
    {
      question_id: IeltsEliteFormFieldsIds.phone,
      ...formDetails.phone,
    },
  ],
};

export const ieltsEliteJsonV4: any = {
  form_id: 'Ielts Demo Form V4',
  form_meta: {
    title: '',
    image: '',
  },
  no_of_steps: 6,
  page_distribution: [2, 3],
  is_auto_progressive: false,
  required: ieltsEliteFormRequiredFields,
  fields: [
    {
      question_id: IeltsEliteFormFieldsIds.whatCareerStage,
      label: 'Ielts Type',
      type: 'single_select_tile',
      fieldValue: IeltsEliteFormFields.whatCareerStage,
      question_text: 'What describes you the best',
      options: [
        {
          label: 'Student',
          value: ieltsCareerStage.STUDENT,
        },
        {
          label: 'Working',
          value: ieltsCareerStage.WORKING,
        },
        {
          label: 'Recently Graduated',
          value: ieltsCareerStage.RECENTLY_GRADUATED,
        },
      ],
      default_visibility: true,
      validation: { pattern: '', errorMessage: '' },
    },
    {
      question_id: IeltsEliteFormFieldsIds.whenIelts,
      label: 'Ielts Type',
      type: 'single_select_tile',
      fieldValue: IeltsEliteFormFields.whenIelts,
      question_text: 'When are you giving IELTS?',
      options: [
        {
          label: 'Within 3 months',
          value: ieltsExamTime.WITHIN_3_MONTHS,
        },
        {
          label: 'Within 3-6 months',
          value: ieltsExamTime.WITHIN_3_6_MONTHS,
        },
        {
          label: 'Already Booked',
          value: ieltsExamTime.BOOKED_EXAM,
        },
      ],
      default_visibility: true,
      validation: { pattern: '', errorMessage: '' },
    },
    {
      question_id: IeltsEliteFormFieldsIds.name,
      ...formDetails.name,
    },
    {
      question_id: IeltsEliteFormFieldsIds.emailId,
      ...formDetails.email,
    },
    {
      question_id: IeltsEliteFormFieldsIds.countryCode,
      ...formDetails.countryCode,
    },
    {
      question_id: IeltsEliteFormFieldsIds.phone,
      ...formDetails.phone,
    },
  ],
};

export enum FormVersions {
  MENA_FORM = '2',
  ONBOARDING_QUESTION_ADD = '3',
}
