import CustomInputSelectionCheckbox from 'components/Common/InputsAndUtils/CustomInputSelectionCheckbox';
import { FunctionComponent, useMemo } from 'react';
import { Field } from 'react-final-form';

interface Props {
  question: string;
  subText?: string;
  input: any;
  label: string;
  options: any;
  meta: any;
  className?: string;
  isMultiSelect?: boolean;
  numGridColumns?: number;
  onClick?: Function;
  setValue?: Function;
  maxSelect?: number;
  nudgeOption?: { country: string; intake: string };
  fieldValue?: string;
  classicForm?: boolean;
  gridLayoutCss?: string;
}
const CustomInputSelect: FunctionComponent<Props> = ({
  input,
  options,
  meta,
  isMultiSelect,
  subText,
  numGridColumns,
  onClick,
  setValue,
  nudgeOption,
  fieldValue,
  classicForm,
  gridLayoutCss,
}) => {
  const gridColCssStr = numGridColumns
    ? `grid-cols-2 md:grid-cols-${numGridColumns}`
    : 'grid-cols-3';
  return (
    <>
      {subText && (
        <div className="text-lightgray-800 text-[14px] md:text-base pb-4">
          {subText}
        </div>
      )}
      <div className={`grid ${gridColCssStr} gap-x-3 ${gridLayoutCss}`}>
        {options.map((option: any, i: number) => {
          const { label, value, icon } = option;
          return (
            <label
              key={i}
              className={`"mx-auto ${
                numGridColumns == 2 ? 'md:first:justify-self-end' : ''
              }`}
            >
              <Field
                name={input.name}
                component={CustomInputSelectionCheckbox}
                type={isMultiSelect ? 'checkbox' : 'radio'}
                value={value}
                isMultiSelect={isMultiSelect}
                label={label}
                icon={icon}
                optionSize={options.length}
                onClick={onClick}
                setValue={setValue}
                nudgeThisOption={
                  nudgeOption ? nudgeOption.intake == value : false
                }
                fieldValue={fieldValue}
                classic={classicForm}
              />
            </label>
          );
        })}
      </div>
      {meta.touched && meta.error && (
        <p className={`mt-1 text-xs text-red-600 md:pl-2 pl-4`}>{meta.error}</p>
      )}
    </>
  );
};

export default CustomInputSelect;
